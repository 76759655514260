/* eslint-disable no-promise-executor-return */
import React from 'react';
import axios from 'axios';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import HomePage from '../components/dashboard/HomePage';
import { ENDPOINT, CANONICAL_URL } from '../api/constants';

function Home({ data }) {
  return (
    <>
      <NextSeo
        title='Home | Online Casino India - Trusted, Secure and Legal | Bleon77'
        description='Bleon77 Online Casino India is the trusted, secure and legal online casino with a variety of games from slot machines to roulette and poker.'
        canonical={`${CANONICAL_URL}`}
      />
      <HomePage data={data} />
    </>
  );
}

export async function getServerSideProps({ req, locale }) {
  const options = {
    method: 'GET',
    url: `${ENDPOINT}/branches`,
    params: { _id: process.env.BRANCH_ID },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let branchData;
  let banners;

  // get data
  await Promise.all([
    // get branch info
    new Promise((resolve) => axios.request(options)
      .then((result) => resolve(result?.data))
      .catch((err) => resolve(err))),
  ])
    .then(async ([branchInfo]) => {
      banners = branchInfo?.data?.[0]?.banners || [];
      branchData = branchInfo?.data?.[0] || [];
    });

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: {
        ...branchData,
        branchId: process.env.BRANCH_ID,
        banners,
      },
    },
  };
}

export default Home;
